.root-inner {
  --accent-1-color: #cdf;
  --accent-2-color: #89a;
  --accent-3-color: #245;
  --bg-color: #fff;
  --text-color: #000;
  --disabled-color: #89a;
  --knob-color: #cdf;

  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  color: var(--text-color);
  background-color: var(--bg-color);
  padding: 0 0.8rem;
  overflow-x: hidden;
}

.root-inner.dark {
  --accent-1-color: #334;
  --accent-2-color: #77A;
  --accent-3-color: #AAC;
  --bg-color: #000;
  --text-color: #fff;
  --disabled-color: #334;
  --knob-color: #e5e5f5;
}

/* need to specify for backdrop... 
.root-inner ::backdrop {
  --text-color: #000;
  --bg-color: #fff;
}
.root-inner.dark ::backdrop {
  --text-color: #fff;
  --bg-color: #000;
}
*/

.prompt {
  font: 1.8rem Arial;
  font-weight: 600;
  text-align: center;
  margin: 1.6rem auto;
  max-width: 50rem;
  position: relative;
}

.game-over-msg {
  background-color: var(--bg-color);
  padding: .5rem;
  position: absolute;
  width: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid var(--text-color);
  border-radius: .5rem;
  animation: fade-out 4s ease-in forwards;
  font-size: 2rem;
  white-space: nowrap;
}


.guess-btn {
  display: block;
  font-size: 2rem;
  margin: 2rem auto;
  padding: 0.8rem 3.2rem;
  cursor: pointer;
  border-radius: .5rem;
}

button {
  cursor: pointer;
  border-radius: .5rem;
  background-color: var(--accent-3-color);
  color: var(--bg-color);
  padding: 0;
}

button:hover {
  filter: brightness(1.2);
}

button:active {
  filter: brightness(1.3);
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: var(--accent-3-color);
  filter: brightness(.7);
  cursor: auto;
}


@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0%);
  }
}

.sortableItem {
  height: 6.4rem;
  display: flex;
  align-items: center;
  border: 2px solid var(--bg-color);
  background-color: var(--accent-1-color);
  color: var(--text-color);
  border-radius: .6rem;
  font: 1.8rem Arial;
  line-height: 1.8rem;
  touch-action: none;
  cursor: grab;
  padding-right: 1.2rem;
  user-select: none;
  width: 100%;
  box-sizing: border-box;
}

.sortableItem.disabled {
  touch-action: manipulation;
  cursor: auto;
}

.drag-container {
  max-width: 50rem;
  margin: 0 auto;
}

.grip-icon {
  color: var(--accent-3-color);
  padding: 0 1.2rem;
}

.sortableItem.disabled .grip-icon {
  opacity: 0.3;
}

.answer {
  font-size: 1.4rem;
  margin-left: auto;
  padding-left: 1.2rem;
  text-align: right;
}

.flex {
  display: flex;
}