.header-row {
  display: flex;
  width: 100%;
  padding-top: 0.8rem;
  flex-flow: row;
  border-bottom: 2px solid var(--accent-3-color);
  align-items: center;
  justify-content: center;
}

h1 {
  flex: 1;
  margin: 0;
  font-family: Arial;
  text-align: center;
  justify-self: start;
  max-width: 30.8rem; /* 500 - 192 */
  display: inline;
  user-select: none;
}

.head-icon {
  cursor: pointer;
  color: var(--accent-3-color);
  padding: 0.75em 1em;
}
