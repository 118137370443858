
.dialog {
  max-width: min(60ch, 100% - 1.6rem);
  min-width: 25rem;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  max-height: calc(100vh - 3em);
  background-color: var(--bg-color);
  color: var(--text-color);
  border: .3rem solid var(--text-color);
  border-radius: 1rem;
}

.dialog[open] {
  animation: slide-up 500ms forwards, fade-in 300ms forwards;
}

.dialog::backdrop {
  background-color: black;
  opacity: 0.7;
}

.dialog[closing] {
  display: block;
  pointer-events: none;
  inset: 0;
  animation: fade-out 200ms forwards;
}

.dialog-inner {
  box-sizing: border-box;
  position: relative;
  padding: 2.4rem;
  overflow: auto;
  height: 100%;
  max-height: calc(100vh - 3em - .6rem);
  min-height: fit-content;
}

@media screen and (max-width: 32em) {
  .dialog-inner {
    padding: 2.4rem 0.8rem;
  }
}


.head-row {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1.6rem;
  font-weight: 500;
}

.close-btn {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.8rem 1.6rem;
  padding: 0.8rem;
  background-color: transparent;
  border: none;
  color: var(--text-color);
  font-size: 1.6rem;
}

/* Help */
.example-box p {
  margin-top: 0;
}

/* History */
.history-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin: 0 auto;
}

.disabled-notice {
  text-align: center;
  margin-bottom: 1.6rem;
}

@media screen and (max-width: 32em) {
  .history-row {
    max-width: 27rem;
    flex-flow: row wrap;
  }

}

.history-btn {
  height: 6rem;
  width: 6rem;
  font-size: 1.2rem;
  margin: .2rem;
  white-space: nowrap;
  text-align: center;
}

.weekday {
  padding-top: 0.2rem;
  font-size: 1.6rem;
}

.check {
  color: #7ed37e;
  font-weight: 600;
}

.root-inner.dark .check {
  color: #1c701c;
}

.countdown-sm {
  font-size: 1.1rem;
}

/* Settings */
.settings-grid {
  display: grid;
  gap: 1.6rem;
  align-items: center;
  grid-template-columns: auto max-content;
  justify-content: center;
}

.toggle-switch {
  position: relative;
  width: 6rem;
  height: 3.4rem;
  background-color: var(--disabled-color);
  border-radius: 99rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.toggle-switch.switched {
  background-color: var(--accent-3-color);
  transition: background-color .4s;
}

.toggle-switch:focus-within {
  outline: 1px solid var(--accent-1-color);
  outline-offset: .3rem;
}

.toggle-switch input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch::before {
  content: "";
  position: absolute;
  background-color: var(--knob-color);
  left: .4rem;

  width: 2.6rem;
  height: 2.6rem;

  position: absolute;
  cursor: pointer;

  transition: transform 0.4s;
  border-radius: 99rem;
  transform: translateX(0);
}

.toggle-switch.switched::before {
  transform: translateX(2.6rem);
}

.dark-light .moon {
  font-size: 2rem;
  color: white;
}

.dark-light .sun {
  font-size: 2rem;
  color: #e6e657;
}

.dark-light.dark-light {
  background-color: #555;
}

.dark-light.dark-light::before {
  background-color: #ccc;
}

/* Stats */
.stats-wrap {
  min-width: fit-content;
  max-width: 45rem;
  margin: 0 auto;
}

.num-row {
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
  text-align: center;
}

.num-tile {
  font-size: 3.5rem;
  line-height: 3.8rem;
}
.num-tile > .tile-title {
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.countdown-label {
  text-align: center;
  margin-top: 1.2rem;
}
.countdown {
  text-align: center;
  font-size: 3.2rem;
  line-height: 3.2rem;
}

.shareBtn {
  float: right;
  padding: 0.8rem 1.6rem;
}

.chart-container {
  padding: 1.6rem 0.8rem;
  margin: 0 auto;
}

.chart-line {
  display: flex;
  width: 100%;
  margin-bottom: 0.4rem;
}

.bar {
  box-sizing: border-box;
  text-align: right;
  display: inline-block;
  margin-left: 0.8rem;
  min-width: 1.6rem;
  padding-right: 0.4rem;
  background-color: var(--accent-1-color);
}

.bar.active {
  background-color: var(--accent-3-color);
  color: var(--bg-color);
}
