.loadContainer {
  font-size: 4.8rem;
  margin: 8rem auto;
  font-weight: 600;
  animation: spin 2s forwards;
  position: relative;
  overflow: hidden;
}

.loadContainer::after {
  content:"rder\00a0up!";
  display: inline-block;
  animation: slideAppear 2s forwards;
  max-width: 0;
  width: auto;
}

@keyframes spin {
  0% {
    opacity: 0;
    transform: translateY(0%) rotate(180deg);
  }
  40% { 
    opacity: 1;
    transform: translateY(100%) rotate(-20deg);
  }
  75% {
    transform: translateY(100%) rotate(1deg);
  }
  80%, 100% {
    transform: translateY(100%) rotate(0deg);
  }

}

@keyframes slideAppear {
  0% {
    max-width: 0;
  }
  40% { 
    max-width: 0;
  }
  85%, 100% {
    width: auto;
    max-width: 30rem;
  }
}
