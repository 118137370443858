.score-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  gap: 1rem;
  width: 100%;
  max-width: 50rem;
  margin: 0 auto 1rem;
}

.score-box {
  border: 2px solid var(--accent-2-color);
  border-radius: .5rem;

  font: 1.8rem Arial;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 2rem;
}

.score-box > * {
  flex: 1 1 0;
  max-width: 100%;
  width: 0;
  user-select: none;
}

.score-box>.score {
  flex: 0 0 6.4rem;
  width: 6.4rem;
  text-align: right;
  padding-right: .6rem;
  animation: slide-up 200ms forwards, fade-in 100ms forwards;
  user-select: none;
}

.score-box .guess-label {
  font-size: 1.3rem;
  padding: 0 0.8rem;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
  animation: slide-up 500ms forwards, fade-in 300ms forwards;
}

.tooltip {
  pointer-events: none;
  user-select: none;
}