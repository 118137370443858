:root {
  font-size: 62.5%;
}

@media screen and (max-width: 32em) {
  :root {
    font-size: 60%;
  }
}

@media screen and (max-width: 25em) {
  :root {
    font-size: 56.25%;
  }
}

body {
  margin: 0;
  font-family: "Segoe UI", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  font-size: 1.6rem;
  min-width: 35rem;
}

